var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-login"},[_c('a-row',[_c('a-col',{attrs:{"lg":{ span: 8, offset: 8 },"xs":{ span: 20, offset: 2 }}},[_c('div',{staticClass:"wrap-login"},[_c('div',{staticClass:"wrap-in-login"},[_c('div',{staticClass:"padding-in-login"},[_c('div',{staticClass:"logo-login"},[_c('img',{attrs:{"src":require('@/assets/img/logo.png')}}),_c('div',[_vm._v("Welcome, Please Login")])]),_c('div',[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"Username"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'username',
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Silahkan isi username anda!',
                          },
                        ],
                      },
                    ]),expression:"[\n                      'username',\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: 'Silahkan isi username anda!',\n                          },\n                        ],\n                      },\n                    ]"}],attrs:{"autocomplete":"off","type":"text","placeholder":"Username"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',{attrs:{"label":"Kata Sandi"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'password',
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Silahkan isi kata sandi anda!',
                          },
                        ],
                      },
                    ]),expression:"[\n                      'password',\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: 'Silahkan isi kata sandi anda!',\n                          },\n                        ],\n                      },\n                    ]"}],attrs:{"type":"password","placeholder":"Kata Sandi"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit","loading":_vm.isLoading}},[_c('a-icon',{attrs:{"type":"lock"}}),_vm._v(" Log in ")],1)],1)],1)])])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }