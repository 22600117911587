<template>
  <div class="bg-login">
    <a-row>
      <a-col :lg="{ span: 8, offset: 8 }" :xs="{ span: 20, offset: 2 }">
        <div class="wrap-login">
          <div class="wrap-in-login">
            <div class="padding-in-login">
              <div class="logo-login">
                <img :src="require('@/assets/img/logo.png')" />
                <div>Welcome, Please Login</div>
              </div>
              <div>
                <a-form :form="form" @submit="handleSubmit">
                  <a-form-item label="Username">
                    <a-input
                      autocomplete="off"
                      v-decorator="[
                        'username',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Silahkan isi username anda!',
                            },
                          ],
                        },
                      ]"
                      type="text"
                      placeholder="Username"
                    >
                      <a-icon
                        slot="prefix"
                        type="user"
                        style="color: rgba(0, 0, 0, 0.25)"
                      />
                    </a-input>
                  </a-form-item>
                  <a-form-item label="Kata Sandi">
                    <a-input-password
                      v-decorator="[
                        'password',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Silahkan isi kata sandi anda!',
                            },
                          ],
                        },
                      ]"
                      type="password"
                      placeholder="Kata Sandi"
                    >
                      <a-icon
                        slot="prefix"
                        type="lock"
                        style="color: rgba(0, 0, 0, 0.25)"
                      />
                    </a-input-password>
                  </a-form-item>

                  <a-button
                    type="primary"
                    html-type="submit"
                    class="login-form-button"
                    :loading="isLoading"
                  >
                    <a-icon type="lock" /> Log in
                  </a-button>
                </a-form>
              </div>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<style src="@/assets/css/style.css" lang="css"></style>
<script>
import { mapState, mapGetters } from "vuex";
export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "normal_login" });
  },

  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState(["token"]),
    ...mapGetters(["isAuth"]),
  },
  created() {
    if (this.isAuth == true) {
      this.$router.push({ name: "homeadmin" });
    }
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        if (!err) {
          this.isLoading = true;
          this.$store
            .dispatch("retrieveToken", {
              username: values.username,
              password: values.password,
            })
            .then((response) => {
              //this.$router.push({ name: "Home" }, () => {});
              this.isLoading = false;
              this.$router.push({ name: "homeadmin" });
              //console.log(response.message);
            })
            .catch((error) => {
              this.isLoading = false;
            });
          // this.$router.push({ name: "Home" }, () => {});
        }
      });
    },
  },
};
</script>
